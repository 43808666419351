import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import store from '../store/store';
import { newTab } from './events/newTab';
import { loadTab } from './events/loadTab';
import { focusTab } from './events/focusTab';
import { closeTab } from './events/closeTab';

const InteractionContext = createContext();

export const useInteraction = () => {
  return useContext(InteractionContext);
};

const InteractionProvider = ({ children }) => {

  const isModalOpen = () => {
    const workspaces = store.getState().workspaces.instances; // ToDo: refactor workspaces state
    const workspace = workspaces.find(workspace => workspace.isActive === true);
    const modals = workspace.modals;
    const isOpen = Object.values(modals).some(modal => modal?.isOpen === true);
    return isOpen;
  };

  const handleMouseDown = async (event) => {
    if (event.detail.event.ctrlKey) {
      await loadTab(event.detail);
    }
  };

  const handleKeyDown = (event) => {
    if (event.altKey && event.key === 'n') {
      if (!isModalOpen())
        newTab();
    }
    if (event.altKey && event.key === 'Delete') {
      closeTab();
    }
    if (event.altKey && event.key.match(/[0-9]/)) {
      if (!isModalOpen()) {
        const getIndex = eventKey => eventKey === 0 ? 9 : eventKey - 1;
        focusTab(getIndex(parseInt(event.key)));
      }
    }
  };

  useEffect(() => {
    const onMouseDown = (event) => {
      handleMouseDown(event);
    };

    const onKeyDown = (event) => {
      handleKeyDown(event);
    };

    window.addEventListener('custom-mousedown', onMouseDown);
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('custom-mousedown', onMouseDown);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [handleMouseDown, handleKeyDown]);

  const contextValue = {
    handleMouseDown,
    handleKeyDown,
  };

  return (
    <InteractionContext.Provider value={contextValue}>
      {children}
    </InteractionContext.Provider>
  );
};

InteractionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InteractionProvider;

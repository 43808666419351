import { getState } from '../../../api/query/static/run.getState';
import { openMultiUpload } from '../modals/actions';
import store from '../../store';
import types from '../types';

export const updateWidgets = (index: number, widgets, layout) => {
  return {
    type: types.WIDGETS_UPDATE,
    index: index,
    widgets: widgets,
    layout: layout
  };
};

export const removeModalWidget = (key: number, id: string) => {
  return {
    type: types.WIDGETS_REMOVE_MODAL_WIDGET,
    key: key,
    id: id
  };
};


export const fetchWidgets = async (key, id) => {
 
  try {
    const response = await getState('system', key, id ?? null);
    const { widgets, layout } = response || {};
    
    if (widgets) {
      for (const element of widgets) {
        if (element.widget === 'Upload') {
          store.dispatch(openMultiUpload(element));
        } else {
          const activeTabIndex = store.getState().workspaces.activeWorkspace.index;
          const oldWidgets = store.getState().workspaces.instances[activeTabIndex]?.widgets?.widgets || [];
          const shouldLoadWidgets = oldWidgets.length === 0 || layout;
          const widgetMap = new Map();
            
          if (!shouldLoadWidgets) {
            oldWidgets.forEach((oldWidget) => {
              const key = `${oldWidget.position.column}-${oldWidget.position.row}-${oldWidget.modal}`;
              widgetMap.set(key, oldWidget);
            });
          }
            
          widgets.forEach((newWidget) => {
            newWidget.dataID = id;
            const key = `${newWidget.position.column}-${newWidget.position.row}-${newWidget.modal}`;
            widgetMap.set(key, newWidget);
          });
            
          const newWidgets = Array.from(widgetMap.values());

          await store.dispatch(updateWidgets(activeTabIndex, newWidgets, layout));
        }
      }
    }
  } catch (error) {
    console.error(`fetchWidgets error: ${error}`);
  }
};
    
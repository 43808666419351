import React, { useState, useRef, useEffect } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent, ContextMenu, Filter, Inject, Page, ExcelExport, PdfExport, Resize, Toolbar, InfiniteScroll, Sort } from '@syncfusion/ej2-react-grids';
import { setGridData } from '../services/setGridData';
import { WidgetProvider } from '../../WidgetContext';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { setCustomContextMenu } from '../services/setContextMenu';
import { getValue } from '@syncfusion/ej2-base';
// import { setDataCounter } from '../services/dataCounter/setDataCounter';
import { contextMenuClick } from '../services/contextMenuClick';
import { linkElements } from './events/linkElements';
import { rowSelecting } from './events/rowSelecting';
import { useTranslation } from 'react-i18next';
import { getSettingsLink } from './settings';
import { insertFilterValues, setFilterValues } from '../services/setFilterValues';
import { setKeyDownRemoveFilter } from '../services/setKeydownEvent';
import PropTypes from 'prop-types';
import './../styles.css';
import { setFilterDateTimeTemplate } from '../services/setDateTimeFilter';

const GridWidgetLink = ({ navData, actionsState}) => {
  const [gridData, setGridDataState] = useState(null);
  const [gridEditData, setGridEditData] = useState([]);
  const [timeFormat, setTimeFormat] = useState('');
  const [dateFormat, setDateFormat] = useState('');
  // const [dataCounterString, setDataCounterString] = useState('');
  const { t } = useTranslation();
  let selIndex = [];

  const gridLinkInstance = useRef(null);

  const widgetContext = { 
    instance: gridLinkInstance.current,
    gridEditData: gridEditData,
    widgetData: navData.widgetData,
    actionsState: actionsState 
  };

  useEffect(() => {
    const handleKeyDown = (event) => setKeyDownRemoveFilter(event, gridLinkInstance.current);
    setGridDataState(null);
    setGridData(setGridDataState, setDateFormat, setTimeFormat, navData, actionsState);
    setTimeout(()=> {  insertFilterValues(gridLinkInstance.current, navData);}, 500);

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      setGridDataState(null);
      setGridEditData([]);
      setDateFormat('');
      setTimeFormat('');
      // setDataCounterString('');
      window.removeEventListener('keydown', (event) => setKeyDownRemoveFilter(event, gridLinkInstance.current));
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);


  const created = () => {
    setGridEditData(gridData);
    window.addEventListener('keydown', (event) => setKeyDownRemoveFilter(event, gridLinkInstance.current));
  };

  const dataBound = () => {
    if (gridLinkInstance.current && selIndex.length) {
      gridLinkInstance.current.selectRows(selIndex);
      selIndex = [];
    }
  };

  const rowDataBound = (args) => {
    if (getValue('selected', args.data) === true) {
      selIndex.push(parseInt(args.row.getAttribute('data-rowindex'), 0));
    }
  };

  const actionComplete = async (args) => {
    if (actionsState && navData) {
      await setFilterValues(args, navData.widgetData.key);
    }
    // await setDataCounter(args, { setState: setDataCounterString, t });
  };

  if (!navData?.widgetData || !gridData || !timeFormat || !dateFormat) {
    return null;
  }

  const settings = getSettingsLink(navData);

  return (
    <div className='widget-container'>
      <WidgetProvider value={widgetContext}>
        {navData.widgetData.menu ? (
          <MenuBar 
            key={`menu-${navData.widgetData.menu.id}`} 
            menu={navData.widgetData.menu} 
          />
        ) : null}
        {navData.widgetData.label !== '' ? <h3 className='widget-label'>{t(navData.widgetData.label)} </h3> : ''}
        <div className='widget-content'>
          <GridComponent
            locale={JSON.parse(localStorage.getItem('language'))}
            ref={(g) => (gridLinkInstance.current = g)}
            id={`grid-${navData.widgetData.key}`}
            key={`grid-${navData.widgetData.key}`}
            height={navData.widgetData.height ? navData.widgetData.height : '100%'}
            rowHeight={28}
            dataSource={gridData}
            className={`grid-widget${navData.widgetData.type === 'link' && navData.widgetData.rowSelection === 'single' ? ' e-link-grid' : ''}`}
            allowExcelExport={true}
            allowPdfExport={true}
            allowFiltering={true}
            allowResizing={true}
            allowSelection={true}
            allowSorting={true}
            allowPaging={navData.widgetData.allowPaging}
            enableInfiniteScrolling={!navData?.widgetData?.allowPaging}
            filterSettings={settings.filterSettingsMenu}
            contextMenuItems={setCustomContextMenu(navData)}
            pageSettings={navData?.widgetData?.allowPaging ? settings.pageSettings : settings.pageSettingsInfinite}
            created={created}
            rowSelected={(args) =>linkElements(gridData, setGridEditData, navData, args, true, gridLinkInstance.current)}
            rowDeselected={(args) => linkElements(gridData, setGridEditData, navData, args, false, gridLinkInstance.current)}
            rowSelecting={(args) => rowSelecting(gridData, navData, args, gridLinkInstance.current)}
            contextMenuClick={(args) => contextMenuClick(args, gridLinkInstance.current, navData ? setCustomContextMenu(navData) : null)}
            dataBound={dataBound}
            rowDataBound={rowDataBound}
            actionComplete={actionComplete}
          >
            <ColumnsDirective>
              <ColumnDirective
                type='checkbox'
                width='24px'
                customAttributes={settings.customAttributes}
                allowResizing={false}
              />
              {navData.widgetData.columns.map((column) => {
                switch (column.type) {
                case 'boolean':
                  return null;
                case 'code':
                  return (
                    <ColumnDirective
                      key={`field-${column.id}`}
                      headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                      width={column.minWidth}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                      customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                      textAlign={'Center'}
                    />
                  );
                case 'datetime':

                  // eslint-disable-next-line no-case-declarations
                  const filterTemplateDateTime = setFilterDateTimeTemplate({dateFormat, timeFormat}, column, gridEditData, setGridEditData);

                  return (
                    <ColumnDirective
                      key={`field-${column.id}`}
                      field={column.name}
                      width={column.minWidth}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                      customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                      headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                      filterBarTemplate={filterTemplateDateTime}
                      visible={!column.hide}
                      disableHtmlEncode={false}
                      isPrimaryKey={column.name === 'id'}
                      type={'dateTime'}
                      format={`${dateFormat} ${timeFormat}`}
                    />
                  );
                default:
                  return (
                    <ColumnDirective
                      key={`field-${column.id}`}
                      field={column.name}
                      width={column.minWidth}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                      customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                      headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                      filter={settings.filterMenu}
                      visible={!column.hide}
                      disableHtmlEncode={false}
                      isPrimaryKey={column.name === 'id'}
                      type={'string'}
                    />
                  );
                }
              })}
            </ColumnsDirective>
            <Inject services={[ContextMenu, Filter, Page, ExcelExport, PdfExport, Resize, Toolbar, InfiniteScroll, Sort]} />
          </GridComponent>
        </div>
        {/* {!navData?.widgetData?.allowPaging && (
          <DataCounter
            counter={dataCounterString}
          />
        )} */}
      </WidgetProvider>
    </div>
  );
};

GridWidgetLink.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GridWidgetLink;

import { runStaticQuery } from '../../../../../../../api/query/run.staticQuery';
import { jwtDecrypt } from '../../../../../../../api/services/tools/service.jwtDecrypt';

export const getData = async (setGridData, params, tabType) => {
  try {
    const sessionData = JSON.parse(localStorage.getItem('session'));
    const { data } = await jwtDecrypt(sessionData);
    const userUUID = data.user.uuid;

    const response = await runStaticQuery(
      tabType === 'existing' ? 'existingLinkedDiagram' : 'linkedDiagram',
      {
        ...params,
        user: userUUID
      }
    );

    console.log(
      `%c StoredProcedure (${tabType === 'existing' ? 'getExistingLinkedDiagrams' : 'getLinkedDiagrams'}) %c`,
      'background: #F9E79F; color: #000',
      'background: transparent; color: black;',
      {
        params: params,
        response: response
      }
    );

    const arr = Object.values(response);
    const results = arr.filter(element => element !== null);
    setGridData(results);
  } catch (error) {
    console.error(`getData: ${error}`);
  }
};

import React, { useEffect, useRef, useState } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { jwtDecrypt } from '../../../api/services/tools/service.jwtDecrypt';

const DateField = ({ field, isLocked, value, hiddenClass, setNewValue }: any) => {
  const [dateValue, setDateValue] = useState(null);
  const [dateFormat, setDateFormat] = useState('');
  const datepickerInstance = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await jwtDecrypt(JSON.parse(localStorage.getItem('session')));
        const dateFormat = response.data.user.dateFormat ?? '';
        setDateFormat(dateFormat);
        setDateValue(new Date(moment((parseInt(value))).format(`${dateFormat}`)));
      } catch (error) {
        console.log(`jwtDecrypt error: ${error}`);
      }
    };
    fetchData();
  }, []);


  const onChange = (args: any) => {
    setNewValue(args.element.value);
  };


  return (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
      <div className='form-input-element'>
        <DatePickerComponent
          ref={datepickerInstance}
          className='form-input-element'
          name="date"
          value={field.type === 'timestamp' ? dateValue : value}
          change={onChange}
          enabled={!isLocked}
          format={`${dateFormat}`}
        />
      </div>
    </div>
  );
};

DateField.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any
};


export default DateField;


import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';

const fetchTreeGridData = async (setTreeGridData, params, widgetData) => {
  try {
    const response = await runDynamicQuery(params, widgetData);
    setTreeGridData(response);
    return response;
  } catch (error) {
    console.error(`error fetch treegrid data: ${error}`);
  }
};

export default fetchTreeGridData;
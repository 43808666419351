import React, { useEffect } from 'react';
import Header from '../../../components/Header/Header';
import WorkspaceComponent from './workspace';
import { useDispatch, useSelector } from 'react-redux';
import { addPinnedWorkspaces, removeWorkspace, setActiveWorkspace, setIsNewWorkspace } from '../../../store/workspaces/workspaces/actions';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import WorkspaceTabTextbox from './workspace.textbox';
import { getModules } from '../../../store/workspaces/modules/actions';
import DropdownMenuWorkspace from './workspace.dropdown';
import { getWorkspacesPinnedAPI } from '../../../api/services/workspaces/getWorkspacesPinned';


const WorkspacesMainstage = () => {
  const workspaces = useSelector((state: any) => state.workspaces);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const response = await getWorkspacesPinnedAPI();
        const pinnedWorkspaces = response.data.map(JSON.parse);
        dispatch(addPinnedWorkspaces(pinnedWorkspaces));
      } catch (error) {
        console.error(`Error fetching workspaces: ${error}`);
      }
    };
  
    fetchWorkspaces();
  }, []);
  

  const handleTabClick = async (workspace, index) => {
    await dispatch(setActiveWorkspace(index));
  
    if (workspace.isNewTab) {
      await dispatch(getModules());
      await runActions(workspace.data.key, workspace.data.id, workspace.data);
      await dispatch(setIsNewWorkspace(index, false));
    }
  };

  const handleTabCloseClick = async (index) => {
    await dispatch(removeWorkspace(index));
    const newIndex = index === 0 ? 0 : index - 1;
    await dispatch(setActiveWorkspace(newIndex));
    const newActiveWorkspace = index === 0
      ? workspaces.instances.find(workspace => workspace.isNewTab)
      : workspaces.instances[newIndex];
  
    if (newActiveWorkspace?.isNewTab) {
      const { key, id } = newActiveWorkspace.data;
      await runActions(key, id, newActiveWorkspace.data);
      await dispatch(setIsNewWorkspace(newIndex, false));
    }
  };

  return (
    <>
      <div className='workspace-button-bar'>
        {workspaces.instances.map((workspace, index) => {
          if (workspace.isOpen) {
            return (
              <div 
                key={index} 
                className={`workspace-button-container${workspace.isActive ? ' active' : ''}`}
                id={`workspace-cm-target-${index}`}
              > 
                {workspace.isPinned ? <span className="e-icons e-star-filled workspace-star-icon"></span>: null}
                <button
                  key={index}
                  className='workspace-button'
                  onClick={() => handleTabClick(workspace, index)}
                >
                  <WorkspaceTabTextbox workspace={workspace} />
                  <DropdownMenuWorkspace workspaceIndex={index}/>
                </button>
                {workspaces.instances.length > 1 && (
                  <button
                    className='workspace-button e-icons e-close'
                    onClick={() => setTimeout(() => handleTabCloseClick(index), 500)}
                  />
                )}
              </div>
            );
          } else {
            return null; 
          }
        })}
      </div>
      <Header />
      {workspaces.instances.map((workspace, index) => (
        <div
          key={index}
          className={`workspace-content${workspace.isActive ? '' : ' hidden'}`}
        >
          <WorkspaceComponent workspace={workspace} />
        </div>
      ))}
    </>
  );
};


export default WorkspacesMainstage;
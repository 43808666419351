import { runStaticQuery } from '../run.staticQuery';
import { runDynamicQuery } from '../run.dynamicQuery';

export const getGraphData = async (params: any, widgetData: any) => {
  try {
    const dynamicQueryResponse = await runDynamicQuery(params, widgetData);

    if (Object.keys(dynamicQueryResponse).length === 0) {
      return null;
    }
    
    const staticQueryResponse = await runStaticQuery('style', { ...dynamicQueryResponse, ...params });

    return staticQueryResponse;
  } catch (error) {
    console.error(`getGraphData error: ${error}`);
  }
};

import React, { useState, useEffect, useRef } from 'react';
import { ContextMenu, Edit, Filter, DayMarkers, GanttComponent, Inject, Resize, RowDD, Selection, Sort, SplitterSettingsModel, TimelineSettingsModel, Toolbar, LabelSettingsModel } from '@syncfusion/ej2-react-gantt';
import MenuBar from '../../components/MenuBar/MenuBar';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import { runDynamicQuery } from '../../api/query/run.dynamicQuery';
import { WidgetProvider } from '../WidgetContext';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import './styles.css';

const GanttWidget = ({ navData, actionsState }) => {
  const [ganttData, setGanttData] = useState(null);
  const ganttInstance: any = useRef(null);
  const { t } = useTranslation();

  const toolbar = [
    {
      text: 'Standart',
      tooltipText: 'Zoom to default',
      id: 'ZoomToDefaultToolbarButton',
      prefixIcon: 'e-zoom-to-fit'
    },
    'ZoomToFit',
    'ZoomIn',
    'ZoomOut'
  ];

  const labelSettings: LabelSettingsModel = {
    taskLabel: '${progress}%'
  };

  const splitterSettings: SplitterSettingsModel = {
    position: '400px',
    separatorSize: 5,
    view: 'Default'
  };

  const timelineSettings: TimelineSettingsModel = {
    timelineUnitSize: 25,
    weekStartDay: 1,
    topTier: {
      format: 'MMM, yyyy',
      unit: 'Week'
    },
    bottomTier: {
      format: 'dd',
      unit: 'Day',
      count: 1
    }
  };

  const contextMenuItems = [
    navData.widgetData.menu?.context?.elements.map((o) => ({
      text: t(o.label),
      target: '.e-content',
      id: o.events.find((event) => event.type === 'onClick')?.key.toString()
    }))
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
        const stages = response.data.map((stage) => ({
          ...stage,
          duration: moment.duration(stage.duration, stage.unit).asDays(),
          unit: 'days'
        }));
        setGanttData({ ...response, data: stages });
      } catch (error) {
        console.error('Error fetching Gantt data:', error);
      }
    };

    fetchData();
  }, [navData.widgetData.dataID , navData.widgetData.timestamp]);

  
  const getCurrentGanttData = () => {
    const instanceData = ganttInstance.current.currentViewData;

    const ganttEditData = instanceData
      ? instanceData.map((element) => ({
        id: element.id,
        duration: element.ganttProperties.duration,
        durationUnit: element.ganttProperties.unit,
        startDate: element.ganttProperties.startDate,
        endDate: element.ganttProperties.endDate,
        fixed: element.taskData.fixed,
        name: element.ganttProperties.taskName,
        progress: element.ganttProperties.progress,
        sequence: element.taskData.sequence,
        predecessors: element.ganttProperties.predecessor
      }))
      : null;

    return ganttEditData;
  };


  const taskBarSelect = (args) => {
    const eventKey = navData.widgetData.events.find((event) => event.type === 'onClick')?.key;
    runActions(eventKey, args?.data?.id, null);
  };

  const rowSelecting = (args) => {
    const eventKey = navData.widgetData.events.find((event) => event.type === 'onClick')?.key;
    runActions(eventKey, args?.data?.id, null);
  };

  const toolbarClick = (args) => {
    if (ganttInstance.current.currentZoomingLevel.level === 20 && args.item.properties.prefixIcon === 'e-zoomin') {
      args.cancel = true;
      return null;
    }
    const settingsMap = {
      ZoomToDefaultToolbarButton: {
        topTier: { format: 'MMM, yyyy', unit: 'Week' },
        bottomTier: { format: 'dd', unit: 'Day', count: 1 }
      },
      ZoomToDayToolbarButton: {
        topTier: { format: 'dd, MMM', unit: 'Day' },
        bottomTier: { format: 'HH', unit: 'Hour', count: 6 }
      }
    };
    const timelineSettings = settingsMap[args.item.properties.id];

    if (timelineSettings) {
      ganttInstance.current.timelineSettings = { topTier: { format: '' }, bottomTier: { format: '' } };
      ganttInstance.current.timelineSettings = timelineSettings;
    }
    setTimeout(() => {
      ganttInstance.current.scrollToDate(String(ganttInstance.current.projectStartDate));
    }, 200);
  };

  const milestoneTemplate = () => (
    <div className='e-gantt-milestone'>
      <div className='e-milestone-top'></div>
      <div className='e-milestone-bottom'></div>
    </div>
  );

  const actionBegin = (args) => {
    if (args.data?.taskData?.fixed) {
      args.cancel = true;
    }
  };

  const contextMenuClick = (args) => {
    const eventKey = args.item.properties.id;
    const itemID = args.rowData.id;
    runActions(eventKey, itemID, actionsState);
  };

  const contextMenuOpen = () => {
    // const record = args.rowData;
    // if (!record.hasChildRecords) {
    //     args.hideItems.push('Hide Column');
    // }
  };

  const dataBound = () => {
    ganttInstance.current.scrollToDate(String(ganttInstance.current.projectStartDate));
  };
  
  if (!ganttData) return null;

  return (
    <div className='widget-container'>
      <WidgetProvider value={{ getCurrentGanttData: getCurrentGanttData, actionsState: actionsState }}>
        {navData.widgetData.menu ? (
          <MenuBar 
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu} 
          />
        ) : null}
        {navData.widgetData.label !== '' ? <h3 className='widget-label'>{t(navData.widgetData.label)} </h3> : ''}
        <div className='widget-content' id='syncfusionGanttView'>
          <GanttComponent
            locale={JSON.parse(localStorage.getItem('language'))}
            className='taskbar-container'
            ref={ganttInstance}
            height={'100%'}
            width={'100%'}
            dateFormat={ganttData.options.dateFormat}
            highlightWeekends={true}
            workWeek={ganttData.project.workWeek}
            includeWeekend={false}
            dayWorkingTime={ganttData.project.dayWorkingTime}
            projectStartDate={ganttData.project.startDate}
            projectEndDate={ganttData.project.endDate}
            dataSource={ganttData.data}
            holidays={ganttData.holidays}
            taskFields={navData.widgetData.taskFields}
            labelSettings={labelSettings}
            timelineSettings={timelineSettings}
            editSettings={navData.widgetData.editOptions}
            toolbarClick={toolbarClick}
            gridLines='Both'
            splitterSettings={splitterSettings}
            toolbar={toolbar}
            allowSelection={true}
            allowSorting={true}
            allowFiltering={true}
            allowResizing={true}
            enableContextMenu={true}
            allowRowDragAndDrop={true}
            onTaskbarClick={taskBarSelect}
            milestoneTemplate={milestoneTemplate}
            dataBound={dataBound}
            actionBegin={actionBegin}
            contextMenuClick={contextMenuClick}
            contextMenuOpen={contextMenuOpen}
            rowSelecting={rowSelecting}
            contextMenuItems={contextMenuItems[0]}
            taskMode='Auto'
          >
            <Inject services={[Selection, Toolbar, Filter, Sort, Resize, Edit, ContextMenu, RowDD, DayMarkers]} />
          </GanttComponent>
        </div>
      </WidgetProvider>
    </div>
  );
};

GanttWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GanttWidget;

import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { useDispatch, useSelector } from 'react-redux';
import { setWorkspacePinned } from '../../../store/workspaces/workspaces/actions';
import { setWorkspacesPinnedAPI } from '../../../api/services/workspaces/setWorkspacesPinned';

const DropdownMenuWorkspace = ({ workspaceIndex }) => {
  const workspaceData = useSelector((state: any) => state.workspaces.instances[workspaceIndex]);
  const dispatch = useDispatch();

  const pinItem = { text: 'pin', id: 'pin', iconCss: 'e-icons e-star-filled workspace-star-icon' };
  const unpinItem = { text: 'unpin', id: 'unpin', iconCss: 'e-icons e-star-filled' };

  const items = workspaceData.isPinned ? [unpinItem] : [pinItem];

  const handleSelect = async (args) => {
    const pinned = args.item.properties.id === 'pin';
    await dispatch(setWorkspacePinned(workspaceIndex));
    await setWorkspacesPinnedAPI(workspaceIndex, pinned);
  };

  return (
    <ContextMenuComponent
      items={items}
      target={`#workspace-cm-target-${workspaceIndex}`}
      select={handleSelect}
    />
  );
};

DropdownMenuWorkspace.propTypes = {
  workspaceIndex: PropTypes.number.isRequired,
};

export default DropdownMenuWorkspace;
